/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import ExternalLink from '../ExternalLink';
import Link from '../Link';
import useStyles from './styles';

const Button = ({ children, primary, ...rest }) => {
  const styles = useStyles();

  return (
    <button css={styles.btn(primary)} {...rest}>
      {children}
    </button>
  );
};
Button.propTypes = {
  children: PropTypes.node,
  primary: PropTypes.bool,
};

const ButtonLink = ({ children, primary, isExternal, to, ...rest }) => {
  const styles = useStyles();

  if (isExternal) {
    return (
      <ExternalLink href={to} css={styles.btn(primary)} {...rest}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <Link css={styles.btn(primary)} to={to} {...rest}>
      {children}
    </Link>
  );
};
ButtonLink.propTypes = {
  children: PropTypes.node,
  primary: PropTypes.bool,
  isExternal: PropTypes.bool,
  to: PropTypes.string,
};

Button.Link = ButtonLink;

export default Button;
