/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-multi-date-picker';
import useStyles from './styles';
import { DateIcon, CloseMenuIcon, Delete } from '../Icons';
import CustomSelectDropDown from '../CustomSelectDropDown';
import Switch from '../Switch';
import { getCourseList } from '../../services';
import getDateFormat from '../../utils/getDateFormat';


const CourseInfoPopup = props => {
  const styles = useStyles();
  const [numberOfTrainers, setNumberOfTrainers] = useState(
    props?.courseData ? props?.courseData?.noOfCoach : 0
  );
  const [selectedVenueDetails, setSelectedVenueDetails] = useState(null);
  const [courseGrpName, setCourseGrpName] = useState(
    props?.courseData ? props?.courseData?.courseGroupName : ''
  );
  const [courseTypeSelected, setCourseTypeSelected] = useState(
    props?.courseData ? props?.courseData?.courseId : 0
  );

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [datePickerValue, setdatePickerValue] = useState(
    'When do you want the course to run'
  );
  const [newDatePickerVal, setNewDatePickerVal] = useState(
    'When do you want the course to run'
  );

  const [courseDates, setCourseDates] = useState(
    props?.courseData ? props?.courseData?.courseDates : [{ id: 1, date: null }]
  );
  const [courseDate, setCourseDate] = useState(
    props?.courseData ? props?.courseData?.courseDates[0] : null
  );
  const [errorMsg, setErrorMsg] = useState(false);

  const [isVenueConfirmed, setIsVenueConfirmed] = useState(
    props?.courseData ? props?.courseData?.venueConfirmed : false
  );

  const [coursetype, setCourseType] = useState([
    {
      id: 0,
      option: 'Select course type',
    },
  ]);

  useEffect(() => {
    console.log('startDate - ', startDate);
  }, [
    startDate,
    endDate,
    numberOfTrainers,
    courseTypeSelected,
    selectedVenueDetails,
  ]);

  useEffect(() => {
    if(props?.courseTypes && props?.courseTypes?.length > 0){
      const derivedData = props?.courseTypes?.map(course => {
        return { id: course?.courseId, option: course?.name };
      });
      const resultData = [...coursetype, ...derivedData];
      setCourseType(resultData);
    }
    else{
      getCourseList()
      .then(({ data }) => {
        const derivedData = data?.map(course => {
          return { id: course?.courseId, option: course?.name };
        });
        const resultData = [...coursetype, ...derivedData];
        setCourseType(resultData);
      })
      .catch(ex => console.log('Error in getCourseList', ex));
    }
  }, []);

  const onCoachSelect = () => {};

  const numberOfCoachList = [
    {
      id: 0,
      option: '00',
    },
    {
      id: 1,
      option: '01',
    },
    {
      id: 2,
      option: '02',
    },
    {
      id: 3,
      option: '03',
    },
    {
      id: 4,
      option: '04',
    },
    {
      id: 5,
      option: '05',
    },
    {
      id: 6,
      option: '06',
    },
  ];

  const selectQuery = value => {
    ///console.log('selected trainer status -', value);
  };

  const createPendingCourse = () => {
    if (
      courseTypeSelected !== 0 &&
      courseGrpName !== '' &&
      numberOfTrainers !== 0 &&
      courseDates.find(dateObj => dateObj.date === null) === undefined
      //&& courseDate !== null
    ) {
      //showCourseInfoModal(false);
      //showCourseConfirmModal(true);
      props.updateCourseConfirmModal(true);

      let sortedDates;
      if (props?.mode === 'create')
        sortedDates = courseDates?.map(item => item?.date).sort();

      if (props?.mode === 'edit')
        sortedDates = courseDates?.sort((a, b) => {
          if (a.date > b.date) return 1;
          else return -1;
        });

      props.updateCourseData({
        //courseLocation: selectedVenueDetails?.venue1,
        ...props.data,
        courseLocation: props?.data?.courseLocation,
        courseDate: courseDate?.date,
        courseDates: sortedDates,
        courseType: coursetype[courseTypeSelected]?.option,
        courseId: courseTypeSelected,
        noOfCoach: numberOfTrainers,
        courseGroupName: courseGrpName,
        venueConfirmed: isVenueConfirmed === true ? 1 : 0,
      });
    }
  };

  const addNewCourseDate = value => {
    const newItem = { id: courseDates.length + 1, date: null };
    setCourseDates([...courseDates, newItem]);
    setErrorMsg(false);
  };

  const handleDatepickerChange = (val, date) => {
    setErrorMsg(false);
    const updateDatePicker = courseDates.map(field =>
      field.id === val.id ? { ...field, date: date } : field
    );

    const filteredArr = updateDatePicker?.reduce((acc, current) => {
      const x = acc.find(item => item.date === current.date);
      if (!x) {
        return acc.concat([current]);
      } else {
        setErrorMsg(true);
        return acc;
      }
    }, []);

    console.log(filteredArr);
    setCourseDates(filteredArr);
    const lowestDate = updateDatePicker.reduce((r, o) =>
      o.date < r.date ? o : r
    );
    setCourseDate(lowestDate);
  };

  const deleteDatePicker = index => {
    const updatedDatePickerFields = courseDates?.filter((_, i) => i !== index);
    setCourseDates(updatedDatePickerFields);

    if (props?.setDeleteInstances) {
      const datetoDelete = courseDates?.filter((_, i) => i === index);
      props?.setDeleteInstances(datetoDelete);
    }
  };

  return (
    <div css={styles.popup2}>
      <label htmlFor css={styles.courseinfo}>Course information</label>
      {props?.mode === 'edit' && (
        <button
          onClick={props?.cancelCourseEdit}
          style={{
            position: 'absolute',
            right: '12px',
            top: '12px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <CloseMenuIcon color="#000000" />
        </button>
      )}
      <div css={styles.formField}>
        <br />
        <div css={styles.divider} />
        <div css={styles.divider} />
        <label htmlFor css={styles.Coursename}>Course name</label>
        <div css={styles.divider} />
        <p css={styles.content}>
          This note will be included in the email to CDs. Detail any special
          requirements around this course
        </p>
        <div css={styles.divider} />
        <div css={styles.divider} />
        <div css={styles.text}>
          <textarea
            placeholder="Course name"
            className="placeholder"
            onChange={e => {
              setCourseGrpName(e.target.value);
            }}
            value={courseGrpName}
          />
          <div css={styles.pencil}>
            <img src="/images/common/list/pencils.svg" alt='pencil'/>
          </div>
        </div>
        <br />
        <div css={styles.datebox}>
          <label htmlFor css={styles.date}>Select a date range</label>
          <div css={styles.divider} />
          <p css={styles.content}>
            This date range will be communicated to coach developers to confirm
            their availability
          </p>
          <div css={styles.divider} />
          {errorMsg === true && (
            <p css={styles.error}>You cannot select same Date more than once</p>
          )}
          <div css={styles.divider} />
        </div>

        <div css={styles.datePickerListContainer}>
          {courseDates.length > 0
            ? courseDates?.map((value, index) => {
                const dateValue =
                  value?.date === null
                    ? 'When do you want the course to run'
                    : getDateFormat(value?.date,'dd-mm-yyyy');
                return (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      key={value?.id}
                      css={styles.datePickerDiv}
                      id="datePicker"
                    >
                      <DatePicker
                        value={value?.date}
                        onFocusedDateChange={(val, isClicked) => {
                          if (isClicked) {
                            const selMonth =
                              val.month > 9 ? val.month : `0${val.month}`;
                            const selDay =
                              val.day > 9 ? val.day : `0${val.day}`;
                            const dateString = `${val.year}-${selMonth}-${selDay}`;
                            handleDatepickerChange(value, dateString);
                          }
                        }}
                        render={(value, openCalendar) => {
                          return (
                            <button
                              onClick={openCalendar}
                              css={styles.datepickerBtn}
                            >
                              <label htmlFor>{dateValue}</label>
                              <DateIcon color="#8F00FF" />
                            </button>
                          );
                        }}
                        minDate={new Date()}
                      />
                    </div>
                    {index > 0 ? (
                      <button
                        onClick={() => deleteDatePicker(index)}
                        style={{
                          background: 'none',
                          outline: 'none',
                          border: 'none',
                        }}
                      >
                        <span style={{ color: '#8F00FF', cursor: 'pointer' }}>
                          <Delete color="#8F00FF" />
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {}}
                        style={{
                          background: 'none',
                          outline: 'none',
                          border: 'none',
                        }}
                      >
                        <span style={{ color: '#FFFFFF', opacity: 0 }}>
                          <Delete color="#FFFFFF" />
                        </span>
                      </button>
                    )}
                  </div>
                );
              })
            : null}
        </div>
        <button
          onClick={() => addNewCourseDate(datePickerValue)}
          css={styles.addNewDate}
        >
          + add another required date
        </button>
        <br />
        <br />
        <label htmlFor css={styles.date}>Course type</label>
        <div css={styles.divider} />
        <div css={styles.divider} />
        {(props?.mode === 'create' ||
          (props?.mode === 'edit' && coursetype?.length > 1)) && (
          <CustomSelectDropDown
            id="coursetype"
            name="coursetype"
            class="coursetypeSelect"
            optionsList={coursetype}
            selectedOptionValue={selectQuery}
            selectedDefaultValue={courseTypeSelected}
            setSelectedDefaultValue={setCourseTypeSelected}
            ref={null}
            style={styles.dropDownButton}
            inverted={true}
          />
        )}
        <br />
        <label htmlFor css={styles.date}>Number of coach developers</label>
        <div css={styles.divider} />
        <div css={styles.divider} />
        <CustomSelectDropDown
          id="numberofCoaches"
          name="numberofCoaches"
          optionsList={numberOfCoachList}
          selectedOptionValue={onCoachSelect}
          selectedDefaultValue={numberOfTrainers}
          setSelectedDefaultValue={setNumberOfTrainers}
          ref={null}
          style={styles.dropDownButton}
          inverted={true}
        />
        <br />
        <label htmlFor css={styles.date}>Is venue confirmed?</label>
        <div css={styles.divider} />
        <div css={styles.divider} />
        <div css={styles.venueSwitch}>
          <Switch
            isChecked={isVenueConfirmed}
            setChecked={setIsVenueConfirmed}
          />
          <label htmlFor css={styles.content}>{isVenueConfirmed ? 'YES' : 'NO'}</label>
        </div>
        <br />
        <div css={styles.divider} />
        <div css={styles.divider} />
        <button
          css={styles.submitbutton(
            courseTypeSelected === 0 ||
              courseGrpName === '' ||
              numberOfTrainers === 0 ||
              courseDates.find(dateObj => dateObj.date === null) !== undefined
            //|| courseDate === null
          )}
          onClick={() => createPendingCourse()}
        >
          {props?.mode === 'edit' ? 'Update Course' : 'Create pending course'}
        </button>
        <br />
      </div>
    </div>
  );
};

CourseInfoPopup.propTypes = {
  courseData: PropTypes.shape({
    courseGroupName: PropTypes.string,
    courseId: PropTypes.number,
    courseDates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        date: PropTypes.string,
      })
    ),
    noOfCoach: PropTypes.number,
    venueConfirmed: PropTypes.bool
   
  }),
  courseTypes: PropTypes.arrayOf(
    PropTypes.shape({
      courseId: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  updateCourseConfirmModal: PropTypes.func,
  updateCourseData: PropTypes.func,
  cancelCourseEdit: PropTypes.func,
  setDeleteInstances: PropTypes.func,
  mode: PropTypes.oneOf(['create', 'edit']),
  data: PropTypes.shape({
    courseLocation: PropTypes.string,
  })
}

export default CourseInfoPopup;
