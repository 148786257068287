/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '../../hooks/useMediaQuery';
import { ArrowTriangle } from '../Icons';
import useStyles from './styles';

const CustomSelectDropDown = ({
  id,
  optionsList,
  selectedOptionValue,
  selectedDefaultValue,
  setSelectedDefaultValue,
  ...props
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(selectedDefaultValue);

  const styles = useStyles();
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');

  let menuRef = useRef();
  const listRef = useRef(null);

  useEffect(() => {
    let handler = e => {
      if (!menuRef.current.contains(e.target)) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  useEffect(() => {
    if (isOptionsOpen && listRef.current) {
      const listItems = Array.from(listRef.current.children);
      if (listItems.length > 0) {
        listItems[selectedDefaultValue].focus();
        setFocusedIndex(selectedDefaultValue);
      }
    }
  }, [isOptionsOpen]);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const selectOption = index => {
    setSelectedDefaultValue(index);
    setFocusedIndex(index);
    setIsOptionsOpen(false);
  };

  const handleListKeyDown = e => {
    const listItems = Array.from(listRef.current.children);
    let nextIndex = focusedIndex;

    switch (e.key) {
      case 'ArrowDown':
        nextIndex =
          focusedIndex < listItems.length - 1 ? focusedIndex + 1 : focusedIndex;
        break;
      case 'ArrowUp':
        nextIndex = focusedIndex > 0 ? focusedIndex - 1 : focusedIndex;
        break;
      case 'Enter':
        if (focusedIndex >= 0) {
          selectOption(focusedIndex);
        }
        break;
      default:
        break;
    }

    if (nextIndex >= 0 && nextIndex < listItems.length) {
      setFocusedIndex(nextIndex);
      setSelectedDefaultValue(nextIndex);
      listItems[nextIndex].focus();
      e.preventDefault();
    }
  };
  return (
    <div css={styles.wrapper} id={id} ref={menuRef}>
      {isDesktop && (
        <div css={styles.container}>
          <button
            type="button"
            onClick={toggleOptions}
            css={props.style ? props.style : styles.dropDownButton}
            aria-haspopup="listbox"
            aria-expanded={isOptionsOpen}
            tabIndex={0}
            onKeyDown={handleListKeyDown}
            onChange={selectedOptionValue(selectedDefaultValue)}
            className={props.class}
          >
            {optionsList?.length > 0 &&
              optionsList[selectedDefaultValue]?.option}
            <span css={styles.arrow(isOptionsOpen)}>
              <ArrowTriangle width={10} height={5} color="#8F00FF" />
            </span>
          </button>
          {isOptionsOpen && (
            <ul
              css={styles.list(props?.inverted)}
              ref={listRef}
              tabIndex={-1} // NOSONAR
            >
              {optionsList.map((data, index) => (
                <li
                  key={data.id}
                  onClick={() => selectOption(index)} // NOSONAR
                  onKeyUp={e => e.key === 'Enter' && selectOption(index)} // NOSONAR
                  tabIndex={isOptionsOpen ? 0 : -1} // NOSONAR
                  css={styles.noOutline}
                  onKeyDown={handleListKeyDown} // NOSONAR
                >
                  {data.option}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      {!isDesktop && (
        <div css={styles.selectWrapper}>
          <select onChange={e => selectedOptionValue(e.target.value)}>
            {optionsList &&
              optionsList.length > 0 &&
              optionsList.map((data, index) => {
                return <option key={data.id}>{data.option}</option>;
              })}
          </select>
          <span css={styles.holder}></span>
        </div>
      )}
    </div>
  );
};

CustomSelectDropDown.propTypes = {
  id: PropTypes.string.isRequired,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      option: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptionValue: PropTypes.func.isRequired,
  selectedDefaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  setSelectedDefaultValue: PropTypes.func.isRequired,
  style: PropTypes.object,
  class: PropTypes.string,
  inverted: PropTypes.bool,
};

export default CustomSelectDropDown;
