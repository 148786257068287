import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, borderRadius } = useTheme();

  return {
    dropDownButton: css`
      color: ${colors.white};
      background: transparent;
      //margin-top: -10px;
      padding: 10px 16px;
      border-radius: 4px;
      border: 2px solid #e6e6e6;
      width: 100%;
      max-width: 100% !important;
      //max-width: 366px;
      justify-content: flex-start;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      height: 56px;

      &:hover,
      &:not(:disabled):hover {
        cursor: pointer;
      }
    `,
    arrow: isOptionsOpen => css`
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 5px 10px;
      text-align: center;
      border-radius: 0 ${borderRadius.s} ${borderRadius.s} 0;
      background-color: transparent;
      display: grid;
      color: ${colors.white};
      align-items: center;
      svg {
        transform: rotate(${isOptionsOpen ? '180deg' : '0deg'});
        > g {
          fill: transparent;
        }
      }
    `,
    container: css`
      position: relative;
      ul {
        border: 1px solid ${colors.lightGrey};
        list-style: none;
        padding: ${borderRadius.m} 0;
        position: absolute;
        width: 100%;
        z-index: 2;
        //max-height: 300px;
        max-height: 180px;
        overflow-y: auto;

        ::-webkit-scrollbar {
          width: 4px;
        }
        ::-webkit-scrollbar-thumb {
          background: #8f00ff;
        }
        ::-webkit-scrollbar-track {
          background-color: ${colors.white};
          -webkit-border-radius: 1rem;
          border-radius: 1rem;
        }

        li {
          padding: 8px 16px;
          color: ${colors.black};
          cursor: pointer;
          font-size: 16px;
          line-height: 24px;
          height: 56px;
          display: flex;
          align-items: center;

          &:active,
          &:focus,
          &:hover,
          &[aria-selected='true'] {
            background: ${colors.disabled};
          }
        }
      }
    `,
    list: inverted => css`
      background-color: ${colors.white};
      bottom: ${inverted && '100%'};
    `,
    selectWrapper: css`
      position: relative;
      select {
        color: ${colors.darkGrey};
        width: 100%;

        // supports in IOS versions only
        @supports (-webkit-touch-callout: none) {
          height: 33px;
          color: ${colors.darkGrey};
          background-color: ${colors.white};
        }
      }
    `,
    holder: css`
      position: absolute;
      display: inline-block;
      right: 0;
      top: 0;
      width: 50px;
      height: 32px;
      background: url(${publicPath('/images/common/dropdown.jpg')}) no-repeat
        right center;
      pointer-events: none;
    `,

    wrapper: css`
      width: 100%;
    `,

    noOutline: css`
      outline: none;
    `,
  };
};
