export const filterOptionsList = [
    {
      title: 'VENUE STATUS',
      filterList: {
        options: [
          {
            id: 1,
            optionVal: 'allVenues',
            optionLabel: 'ALL',
            icon: '',
          },
          {
            id: 2,
            optionVal: 'confirmedVenues',
            optionLabel: 'CONFIRMED',
            icon: '',
            images: '/images/common/list/green-dot.svg',
          },
          {
            id: 3,
            optionVal: 'pendingVenues',
            optionLabel: 'PENDING',
            icon: '',
            images: '/images/common/list/orange-triangle.svg',
          }
        ],
      },
    },
    {
      title: 'COACH DEVELOPER STATUS',
      filterList: {
        options: [
          {
            id: 1,
            optionVal: 'allCoaches',
            optionLabel: 'ALL',
            icon: '',
          },
          {
            id: 2,
            optionVal: 'confirmedCoaches',
            optionLabel: 'CONFIRMED',
            icon: '',
            images: '/images/common/list/green-dot.svg',
          },
          {
            id: 3,
            optionVal: 'pendingCoaches',
            optionLabel: 'PENDING',
            icon: '',
            images: '/images/common/list/orange-triangle.svg',
          },
          {
            id: 4,
            optionVal: 'declinedCoaches',
            optionLabel: 'DECLINED',
            icon: '',
            images: '/images/common/list/red-square.svg',
          },
          {
            id: 4,
            optionVal: 'noncontactedCoaches',
            optionLabel: 'NOT CONTACTED',
            icon: '',
            images: '/images/common/list/gray-dot.svg',
          },
        ],
      },
    },
    {
      title: 'COURSE TYPE',
      filterList: {
        options: [
          {
            id: 0,
            optionVal: 'all',
            optionLabel: 'ALL',
            icon: '',
          },
        ],
      },
    },
  ];



