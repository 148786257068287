import { useEffect, useState } from 'react';
import {
  getCourseVenueList,
  getCourseVenueData,
  getCourseTrainerList,
  updateCourseVenueData,
  getCourseInstanceDetails,
} from '../../services';
import useErrorBoundary from '../useErrorBoundary';

export default id => {
  const [courseVenueListData, setCourseVenueListData] = useState();
  const [courseVenueEditData, setCourseVenueEditData] = useState();
  const [courseTrainerListData, setCourseTrainerListData] = useState();
  const [courseVenueUpdateData, setCourseVenueUpdateData] = useState();
  const [courseInstanceData, setCourseInstanceData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // const throwError = useErrorBoundary();

  useEffect(() => {
    getCourseVenueList()
      .then(({ data }) => {
        const courseVenueList = data;
        setCourseVenueListData(courseVenueList);
      })
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }
    getCourseVenueData(id)
      .then(({ data }) => {
        if (data) {
          setCourseVenueEditData(data);
        }
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // to get the trainer management map details
  useEffect(() => {
    getCourseTrainerList()
      .then(({ data }) => {
        const courseTrainerList = data;
        setCourseTrainerListData(courseTrainerList);
      })
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }
    updateCourseVenueData(id)
      .then(({ data }) => {
        if (data) {
          courseVenueUpdateData(data);
        }
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCourseInstanceDetails()
      .then(({ data }) => {
        const courseInstData = data;
        setCourseInstanceData(courseInstData);
      })
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    courseVenueListData,
    courseVenueEditData,
    courseTrainerListData,
    courseInstanceData
  };
};
