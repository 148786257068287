export default (date, format) => {
    const dt = new Date(date);
    const yyyy = dt.getFullYear();
    let mm =
      format === 'mm dd'
        ? dt.toLocaleString('default', { month: 'short' })
        : dt.getMonth() + 1;
    let dd = dt.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    if (format === 'dd-mm-yyyy') return `${dd}-${mm}-${yyyy}`;

    if (format === 'yyyy-mm-dd') return `${yyyy}-${mm}-${dd}`;

    if (format === 'mm dd') return `${mm} ${dd}`;
  };