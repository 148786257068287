const redIcon = '/images/common/location-pin-red.svg';
const blueIcon = '/images/common/location-pin-blue.svg';
const userIcon = '/images/common/user-pin.svg';

const baseConfig = {
  showDashboard: true,
  padding: 43,
  zoom: 19,
  minZoom: 6,
  enableClickableLogo: false,
};

const pinBaseConfig = {
  icon: redIcon,
};

/**
 * The map class constructor takes a config object and an array of locations
 * config: {
 *  container: the element in which to render the map
 *  center: a location object for the center pin
 *  handleMapMovement: function to be executed when the map is moved
 * },
 * pushpins: [{ latitude, longitude }]
 */
class MapService {
  map = null;
  currentlySelected = null;
  plottedPins = [];
  latestBound = null;
  hasMapViewChangedOnce = false;
  mouseDownOnPin = false;
  infobox = '';

  constructor(config, pushpins, getVenueIcon, getVenueSelectedIcon, hideFilter) {
    this.config = config;
    this.pushpinObjects = pushpins || [];
    this.getVenueIcon = getVenueIcon;
    this.getVenueSelectedIcon = getVenueSelectedIcon;
    this.hideFilter = hideFilter;

    this.init();
  }

  init() {
    if (this.map) {
      return;
    }

    // we want to make sure we have the maps on the window
    // before we initiate
    if (
      !window?.Microsoft?.Maps ||
      !window?.Microsoft?.Maps?.Location ||
      !window?.Microsoft?.Maps?.Pushpin ||
      !window?.Microsoft?.Maps?.LocationRect
    ) {
      setTimeout(() => {
        this.init();
      }, 500);
    } else {
      this.createMap();
    }
  }

  createLocation(location) {
    return new window.Microsoft.Maps.Location(
      location.latitude,
      location.longitude
    );
  }

  createPin(pin) {
    const location = this.createLocation(pin);
    return new window.Microsoft.Maps.Pushpin(location, {
      ...pinBaseConfig,
      ...(pin.options || {}),
    });
  }

  createView() {
    const pins = this.pushpinObjects.map(this.createLocation.bind(this));

    if (pins.length === 1 && this.config.zoom) {
      return {
        zoom: this.getZoomLevel(
          this.config.zoom,
          this.pushpinObjects[0].latitude
        ),
        center: this.createLocation(this.pushpinObjects[0]),
      };
    }

    const rect = window.Microsoft.Maps.LocationRect.fromLocations(pins);

    return { bounds: rect };
  }

  createMap() {
    if (this.map instanceof window.Microsoft.Maps.Map) {
      return;
    }

    const config = {
      ...baseConfig,
      navigationBarMode: window.Microsoft.Maps.NavigationBarMode.minified,
      mapTypeId: window.Microsoft.Maps.MapTypeId.grayscale,
    };

    const mapRect = this.createView();

    this.map = new window.Microsoft.Maps.Map(this.config.container, {
      ...config,
      ...mapRect,
    });

    this.infobox = new window.Microsoft.Maps.Infobox(this.map.getCenter(), { visible: false });
  
    this.infobox.setMap(this.map);
  
    this.setMapListeners();
    this.addPins();
  }

  setMapListeners() {
    if (!this.map || !this.config.handleMapMovement) {
      return;
    }

    window.Microsoft.Maps.Events.addHandler(this.map, 'mouseup', () => {
      if (this.mouseDownOnPin) {
        return;
      }

      this.config.handleMapMovement();
    });
  }

  addPins(pins) {
    if (pins) {
      this.pushpinObjects = [...pins];
    }

    if (!this.map || this.pushpinObjects.length === 0) {
      return;
    }

    // if the config has the user location we want to show it
    if (this.config.center) {
      // create a pushpin for the user location
      this.pushpinObjects.push({
        id: 'user-location',
        ...this.config.center,
        options: {
          icon: userIcon,
        },
      });
    }

    this.pushpinObjects.forEach(({ handleClick, id, ...singlePin }) => {
      const newPin = this.createPin(singlePin);

      this.plottedPins.push(newPin);
      this.map.entities.push(newPin);

      if (typeof handleClick === 'function') {
        window.Microsoft.Maps.Events.addHandler(newPin, 'click', handleClick);
      }

      // we need to discern between clicks on the map and clicks on the pins
      // not to trigger the map handler if a pushpin is clicked
      window.Microsoft.Maps.Events.addHandler(newPin, 'mousedown', () => {
        this.mouseDownOnPin = true;
      });
      window.Microsoft.Maps.Events.addHandler(newPin, 'mouseup', () => {
        setTimeout(() => {
          this.mouseDownOnPin = false;
        }, 100);
      });
    });

    const bounds = this.createView();
    this.map.setView(bounds);
  }

  removePins() {
    if (!this.map) {
      return;
    }

    this.pushpinObjects = [];
    this.plottedPins = [];
    this.map.entities.clear();
  }

  setSelected(id) {
    // if no id is passed we want to deselect the currently selected pin
    if (!id) {
      this.currentlySelected?.setOptions({ icon: redIcon });
      this.currentlySelected = null;

      if (this.latestBound) {
        this.map.setView({ bounds: this.latestBound });
        this.latestBound = null;
      }
      return;
    }

    // save the latest bound to move the user
    this.latestBound = this.map.getBounds();

    // get the index by id
    const pinIndex = this.pushpinObjects.findIndex(pin => pin.id === id);
   
    // console.log(id);
    // console.log(this.pushpinObjects);
    // console.log(pinIndex);

    if (pinIndex === -1) {
      return;
    }

    // get venue capacity
    const capacity = this.pushpinObjects[pinIndex];

    // find the Pushpin object to compare to the one on the map
    const pinToUpdate = this.plottedPins[pinIndex];
    const entityToUpdate = this.map.entities.indexOf(pinToUpdate);

    // console.log(this.plottedPins);
    // console.log(pinToUpdate);
    // console.log(entityToUpdate);
    // console.log(this.currentlySelected);
    // console.log(this.plottedPins[pinIndex]);
    // console.log(this.pushpinObjects);
    // console.log(this.getVenueSelectedIcon(capacity));
    // console.log(this.map.entities.get(entityToUpdate));


    // clear the previous selected state
    if (this.currentlySelected) {
      const oldCapacity = this.pushpinObjects[this.currentlySelected];
      this.currentlySelected?.setOptions({ icon: this.getVenueIcon(oldCapacity) });
    }

    // update with the new selected state
    this.currentlySelected = this.plottedPins[pinIndex];
    this.map.entities.get(entityToUpdate)?.setOptions({ icon: this.getVenueSelectedIcon(capacity) });

    // center the map
    const center= this.createLocation(this.pushpinObjects[pinIndex]);
    //this.map.setView({ center });

    if (this.hideFilter) {
      const mapObj = this.map;

    const createCircle = (center, radius, color) => {
      //Calculate the locations for a regular polygon that has 36 locations which will rssult in an approximate circle.
      var locs = window.Microsoft.Maps.SpatialMath.getRegularPolygon(center, radius, 36, window.Microsoft.Maps.SpatialMath.DistanceUnits.Miles);

      return new window.Microsoft.Maps.ContourLine(locs, color);
    };

     //draw circle around selected pin
     window.Microsoft.Maps.loadModule(['Microsoft.Maps.SpatialMath', 'Microsoft.Maps.Contour'], function () {
      console.log('555555');
      console.log(center);
      var circle1 = createCircle(center, 40, 'rgba(156, 162, 156, 0.30)');
      var layer = new window.Microsoft.Maps.ContourLayer([circle1], {
        colorCallback: function (val) {
            return val;
        },
        polygonOptions: {
            strokeThickness: 4,
            strokeColor: '#515C50'
        }
      });
      mapObj.layers.clear();
      mapObj.layers.insert(layer);
      mapObj.setView({ center: center, zoom: 9 });
     });
    } else {
      this.map.setView({ center: center, zoom: 9});
    }
  }

  // we're using a formula to calculate the zoom
  // using the space in miles
  // see: https://stackoverflow.com/a/50743144
  getZoomLevel(radiusInMiles, latitude) {
    const rangeInMeters = radiusInMiles * 1600;
    // we take the lower value of the bounding rect of the map, so the area will be best seen
    const limitBoundPixels = Math.min(
      this.config.container.offsetHeight,
      this.config.container.offsetWidth
    );

    // if the element is hidden we return a reasonable default
    if (!limitBoundPixels) {
      return 15;
    }

    const zoom = Math.floor(
      Math.log(
        (156543.03392 * Math.cos((latitude * Math.PI) / 180)) /
          (rangeInMeters / limitBoundPixels)
      ) / Math.log(2)
    );

    return zoom;
  }

  reset() {
    this.map?.dispose();
  }
}

export default MapService;
