import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors } = useTheme();

  return {
    venueActionCta: css`
      display: flex;
      gap: 32px;
      button {
        max-width: 150px;
        cursor: pointer;
      }
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    background: ${colors.black};
    padding: 32px;
}
    `,

    filterWrapper: css`
      width: 226px;
    `,

    filterTitle: css`
      font-size: 15.63px;
      line-height: 15.63px;
      letter-spacing: -0.156px;
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid #4f4f4e;
      color: ${colors.white};
    `,

    filterList: css`
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-height: calc(100vh - 220px);
      overflow-y: auto;
      padding-bottom: 32px;
      padding-right: 24px;

      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.grey};
        cursor: pointer;
      }
      ::-webkit-scrollbar-track {
        background-color: ${colors.darkGrey};
        -webkit-border-radius: 1rem;
        border-radius: 1rem;
      }

      label {
        height: 48px;
      }
    `,
    trainerMap: css`
      color: ${colors.darkBlue};
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.02em;
      text-transform: initial;
      position: relative;
    `,
    trainerMapWrapper: css`
      height: calc(100vh);
      min-height: 400px;

      .MicrosoftMap .Infobox {
        position: relative;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        left: 0;
        top: -50px;
        pointer-events: auto;
        background-color: ${colors.white};
        font-family: 'FS Dillon';
        max-width: 464px;
      }
      .MicrosoftMap .Infobox .infobox-body {
        max-height: initial !important;
        max-width: initial !important;
        width: initial !important;
        padding: 40px;
      }
      .MicrosoftMap .Infobox .infobox-info {
        font-family: 'FS Dillon';
        max-height: initial !important;
        overflow: hidden !important;
      }

      .MicrosoftMap .Infobox .infobox-actions {
        height: auto !important;
        border: none !important;

        li {
          width: 100%;
          margin: 4px 0 !important;

          a {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #8f00ff;
            border-radius: 4px;
            font-family: FS Dillon;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: ${colors.white};
            cursor: pointer;

            &:hover,
            :focus {
              text-decoration: none;
              color: ${colors.white};
            }
          }
        }
      }
      .MicrosoftMap .Infobox .infobox-title {
        display: none;
      }
      .MicrosoftMap .infobox-close {
        color: #777;
        height: 14px;
        outline: medium none;
        position: absolute;
        right: 16px;
        text-align: center;
        text-decoration: none;
        top: 16px;
        width: 14px;
        pointer-events: auto;
      }
      .MicrosoftMap .Infobox .infobox-title {
        color: #333;
      }
      .MicrosoftMap .Infobox .infobox-actions {
        border-top: 1px solid #888;
        margin: 0 0 -4px;
        padding: 4px 0;
        font-size: 0.9em;
        clear: both;
        padding: 4px 8px 8px;
        color: #676767;
        height: 15px;
        overflow: auto;
      }
      stalk,
      .MicrosoftMap .InfoboxCustom .infobox-stalk {
        width: 14px;
        height: 16px;
        background: no-repeat;
        position: absolute;
        bottom: 1px;
      }
      .tooltipWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .tooltipTitle {
        font-size: 24.41px;
        font-weight: 700;
        line-height: 31.4px;
      }
      .tooltipAddress {
        display: flex;
        flex-direction: row;
        gap: 32px;
        margin-top: 8px;
      }
      .addressTitle {
        color: ${colors.black};
        font-size: 15.63px;
        font-weight: 700;
        line-height: 15.63px;
        letter-spacing: -0.156px;
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #e6c6ff;
      }
      .addressDetails {
        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.16px;
          color: #4f4f4e;
        }
        &.bluetext {
          p {
            color: #8f00ff;
            font-weight: 700;
          }
        }
      }
      .facilitiesWrapper {
        margin-top: 8px;
      }
      .facilitiesDetails {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        p {
          display: flex;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.16px;
          color: #4f4f4e;
          min-width: 185px;
          &:before {
            content: '';
            display: flex;
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          &.type {
            &:before {
              background: transparent
                url(${publicPath('/images/common/venue/sports_soccer.svg')})
                no-repeat center center;
            }
          }
          &.treatment {
            &:before {
              background: transparent
                url(${publicPath('/images/common/venue/medical_services.svg')})
                no-repeat center center;
            }
          }
          &.facility {
            &:before {
              background: transparent
                url(${publicPath('/images/common/venue/school.svg')}) no-repeat
                center center;
            }
          }
        }
      }
      .actionCta {
        margin-top: 8px;
        width: 392px;
      }
      .createCta {
        width: 90%;
        height: 54px;
        border-radius: 4px;
        color: ${colors.black};
        background: #f3e3ff;
        border: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 366px;
        min-height: 44px;
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color 0.2s ease-in;
        position: relative;
        white-space: nowrap;
        padding: 0 20px;
        box-sizing: border-box;
        span {
          display: flex;
          &:before {
            content: '';
            display: flex;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            background: transparent
              url(${publicPath('/images/common/venue/school.svg')}) no-repeat
              center center;
          }
        }
      }
    `,
   

    backBtn: css`
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      stylename: CD - H3 Bold;
      font-family: FS Dillon;
      font-size: 18px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.white};
      background: transparent;
      gap: 12px;

      img {
        width: 24px;
        height: 24px;
      }
    `,

    overlay: css`
      width: 100%;
      height: 100%;
      background-color: rgba(29, 29, 27, 0.9);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    `,

  };
};