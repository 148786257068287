/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types'; 
import useStyles from './styles';
import publicPath from '../../utils/publicPath';

const FilterOptions = ({filterOptions, filterTitle, handleClick}) => {
  const styles = useStyles();

  return (
    <div css={styles.base}>
        <div css={styles.filterOptionsTitle}>
          {filterTitle}
        </div>
        <div css={styles.filterOptions}>
          {filterOptions.options.map(list => (
            <div css={styles.option} >
              <input type='checkbox' 
              id={list.optionVal} 
              value={list.optionVal} 
              onChange={e => handleClick(e, filterTitle, list.optionLabel)}
              ></input>
              <label htmlFor={list.optionVal}>
                {list.icon && (
                  <img
                    css={styles.image}
                    alt="user"
                    src={publicPath(list.icon)}
                  />
                )}
                {list.optionLabel}
              </label>
            </div>
          ))}
        </div>
    </div>
  );
};

FilterOptions.propTypes = {
  filterOptions: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        optionVal: PropTypes.string,
        optionLabel: PropTypes.string,
        icon: PropTypes.string,
      })
    ),
  }),
  filterTitle: PropTypes.string,
  handleClick: PropTypes.func,
};

export default FilterOptions;
