/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import useStyles from './styles';

const HiddenContent = ({ children, ...rest }) => {
  const styles = useStyles();

  return (
    <span css={styles.hidden} {...rest}>
      {children}
    </span>
  );
};

HiddenContent.propTypes = {
  children: PropTypes.node,
};

export default HiddenContent;
