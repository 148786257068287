const b2cEnvVariables = env => {
  let b2cSettings = {};
  const envVar = env && env.toLowerCase();
  switch (envVar) {
    case 'development':
      b2cSettings = {
        ClientId: '8f0beae6-97fa-46f6-8f6d-a0351d19f924',
        AuthUrl: 'https://b2cppthefa.b2clogin.com',
        Tenant: 'b2cppthefa.onmicrosoft.com',
        SignUpPolicyId: 'B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVER',
        SignInPolicyId: 'b2c_1a_signup_signin_plyreg',
        PasswordResetPolicyId: 'B2C_1A_PWRESETCUSTOMOTPPFF_STR', // Password Reset Policy Name
        ForgottenEmailPolicyId:
          'B2C_1A_SIGNUP_SIGNINPROFILEEDITCUSTOMOTPFANCODEV3JSENFORCEEMAIL', // SignIn and Recovery Using FAN Code policy
        DontKnowMyFanPolicyId: 'B2C_1A_FARECOVERYCUSTOMOTPPFF_STRUIVER', // Policy for Account Recovery
        ClientSecret: 'C3U8Q~Y92JnAq35EabFMOcBd_ynBmuNRgG7GLch7',
        FAAppToken: 'OT',
        MyAccountUrl: 'https://pffmyaccountpp.thefa.com/#/home',
        b2cScopes: [
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/user_impersonation',
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/READ',
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/WRITE',
        ],
      };
      break;
    case 'qa':
      b2cSettings = {
        ClientId: 'e9b66cc3-cba4-4de7-aab6-c8561df2463a',
        AuthUrl: 'https://b2cqathefa.b2clogin.com',
        Tenant: 'b2cqathefa.onmicrosoft.com',
        SignUpPolicyId: 'B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVERDYN',
        SignInPolicyId: 'B2C_1A_SIGNUP_SIGNINACTDYN',
        PasswordResetPolicyId: 'B2C_1A_PWRESETCUSTOMOTPPFF_STR', // Password Reset Policy Name
        ForgottenEmailPolicyId:
          'B2C_1A_SIGNUP_SIGNINPROFILEEDITCUSTOMOTPFANCODEV3JSENFORCEEMAILDYN', // SignIn and Recovery Using FAN Code policy
        DontKnowMyFanPolicyId: 'B2C_1A_FARECOVERYCUSTOMOTPPFF_STRUIVERDYN', // Policy for Account Recovery
        ClientSecret: 'PvQ7Q~_KrdZ9SMRptBLPx-hgxhlMkj6pp_PMz',
        FAAppToken: 'OT',
        MyAccountUrl: 'https://zqapffwebapp11.z6.web.core.windows.net/#/home',
        b2cScopes: [
          'https://b2cqathefa.onmicrosoft.com/ACTScopeApp/user_impersonation',
          'https://b2cqathefa.onmicrosoft.com/ACTScopeApp/READ',
          'https://b2cqathefa.onmicrosoft.com/ACTScopeApp/WRITE',
        ],
      };
      break;
    case 'staging':
      b2cSettings = {
        ClientId: '2c521b1e-3317-4dc6-940e-2f276e9269c1',
        AuthUrl: 'https://b2cppthefa.b2clogin.com',
        Tenant: 'b2cppthefa.onmicrosoft.com',
        SignUpPolicyId: 'B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVER',
        SignInPolicyId: 'B2C_1A_SIGNUP_SIGNINACTDYN',
        PasswordResetPolicyId: 'B2C_1A_PWRESETCUSTOMOTPPFF_STR', // Password Reset Policy Name
        ForgottenEmailPolicyId:
          'B2C_1A_SIGNUP_SIGNINPROFILEEDITCUSTOMOTPFANCODEV3JSENFORCEEMAIL', // SignIn and Recovery Using FAN Code policy
        DontKnowMyFanPolicyId: 'B2C_1A_FARECOVERYCUSTOMOTPPFF_STRUIVER', // Policy for Account Recovery
        ClientSecret: '.1d7Q~BSksmCkYxAOj6CaqdL.DfQ3P2qpd3SA',
        FAAppToken: 'OT',
        MyAccountUrl: 'https://pffmyaccountpp.thefa.com/#/home',
        b2cScopes: [
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/user_impersonation',
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/READ',
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/WRITE',
        ],
      };
      break;
    default:
      // prod
      b2cSettings = {
        ClientId: '8f0beae6-97fa-46f6-8f6d-a0351d19f924',
        AuthUrl: 'https://b2cppthefa.b2clogin.com',
        Tenant: 'b2cppthefa.onmicrosoft.com',
        SignUpPolicyId: 'B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVER',
        SignInPolicyId: 'b2c_1a_signup_signin_plyreg',
        PasswordResetPolicyId: 'B2C_1A_PWRESETCUSTOMOTPPFF_STR', // Password Reset Policy Name
        ForgottenEmailPolicyId:
          'B2C_1A_SIGNUP_SIGNINPROFILEEDITCUSTOMOTPFANCODEV3JSENFORCEEMAIL', // SignIn and Recovery Using FAN Code policy
        DontKnowMyFanPolicyId: 'B2C_1A_FARECOVERYCUSTOMOTPPFF_STRUIVER', // Policy for Account Recovery
        ClientSecret: 'C3U8Q~Y92JnAq35EabFMOcBd_ynBmuNRgG7GLch7',
        FAAppToken: 'OT',
        MyAccountUrl: 'https://pffmyaccountpp.thefa.com/#/home',
        b2cScopes: [
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/user_impersonation',
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/READ',
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/WRITE',
        ],
      };
      break;
  }
  return b2cSettings;
};

export default b2cEnvVariables;
