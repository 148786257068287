/** @jsxImportSource @emotion/react */
import useStyles from './styles';
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation,
} from 'react-router-dom';
import { useState, useEffect } from 'react';
import CourseManagementList from '../CourseManagementList';
import CourseManagementMap from '../CourseManagementMap';
import CourseManagementAddNew from '../CourseManagementAddNew';
import publicPath from '../../utils/publicPath';
 
const CourseManagementLayout = () => {
  const styles = useStyles();
  const location = useLocation();
 
  const [currentPage, setCurrentPage] = useState('');
 
  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      setCurrentPage('list');
    } else if (path === '/courses/map') {
      setCurrentPage('map');
    } else if (path === '/courses/addnew') {
      setCurrentPage('new');
    }
  }, [location.pathname]);
 
  return (
    <div>
      <div css={styles.headerWrapper}>
        <div css={styles.headerBtnSectionLeft}>
          <img
            id="logo"
            css={styles.logo}
            src={
              currentPage === 'list'
                ? `${publicPath('/images/common/logo_efl_white.svg')}`
                : `${publicPath('/images/common/logo_efl_black.svg')}`
            }
            alt="EFL Logo"
          />
        </div>
        <div css={styles.headerBtnSectionRight}>
          <Link
            to="/"
            css={styles.viewBtn}
            className={currentPage === 'list' ? 'active' : ''}
          >
            <div>
              <img
                src={
                  currentPage === 'list'
                    ? `${publicPath('/images/common/filter/lists_active.svg')}`
                    : `${publicPath('/images/common/filter/lists_inactive.svg')}`
                }
                alt="List View"
              />
            </div>
            LIST VIEW
          </Link>
          <Link
            to="/courses/map"
            css={styles.viewBtn}
            className={currentPage === 'map' ? 'active' : ''}
          >
            <div>
              <img
                src={
                  currentPage === 'map'
                    ? `${publicPath('/images/common/location_active.svg')}`
                    : `${publicPath('/images/common/location_on.svg')}`
                }
                alt="Map View"
              />
            </div>
            MAP VIEW
          </Link>
        </div>
        {
          currentPage !== 'new' &&
          <Link
          to="/courses/addnew"
          css={styles.addCourseBtn}
          className={currentPage === 'new' ? 'active' : ''}
        >
          CREATE A NEW COURSE &nbsp;&nbsp;
          <div>
            <img src={`${publicPath('/images/common/Add.svg')}`} alt="Add Course" />
          </div>
        </Link>
        }
      </div>
 
      <Route path="/" exact component={CourseManagementList} />
      <Route path="/courses/map" component={CourseManagementMap} />
      <Route path="/courses/addnew" component={CourseManagementAddNew} />
    </div>
  );
};
 
export default CourseManagementLayout;