/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; 
import useStyles from './styles';
import publicPath from '../../utils/publicPath';

const FilterOptionsList = ({
  filterOptions,
  filterTitle,
  handleClick,
  resetFilters,
  ...props
}) => {
  const styles = useStyles();

  const [checkedOptions, setCheckedOptions] = useState(['ALL']);

  useEffect(() => {
    if (resetFilters) {
      setCheckedOptions(['ALL']);
      handleClick(null, 'all', 'all');
    }
  }, [resetFilters, filterTitle, handleClick]);

  const handleCheckboxChange = (e, filterTitle, optionLabel) => {
    let updatedOptions = [];

    if (optionLabel === 'ALL') {
      updatedOptions = ['ALL'];
    } else {
      if (checkedOptions.includes('ALL')) {
        updatedOptions = [optionLabel];
      } else {
        updatedOptions = checkedOptions.includes(optionLabel)
          ? checkedOptions.filter(label => label !== optionLabel)
          : [...checkedOptions, optionLabel];
      }
    }

    setCheckedOptions(updatedOptions);
    handleClick(e, filterTitle, optionLabel);
  };

  return (
    <div css={props?.style ? props?.style : styles.base}>
      {filterTitle !== '' &&
        filterTitle !== null &&
        filterTitle !== undefined && (
          <div css={styles.filterOptionsTitle}>{filterTitle}</div>
        )}
      <div css={styles.filterOptions}>
        {filterOptions.options.map(list => (
          <div css={styles.option}>
            <input
              type="checkbox"
              id={list.optionVal}
              value={list.optionVal}
              onChange={e =>
                handleCheckboxChange(e, filterTitle, list.optionLabel)
              }
              checked={checkedOptions.includes(list.optionLabel)}
            ></input>
            <label htmlFor={list.optionVal}>
              {list.icon && (
                <img
                  css={styles.image}
                  alt="user"
                  src={publicPath(list.icon)}
                />
              )}
              <span>{list.optionLabel}</span>

              {!checkedOptions.includes(list.optionLabel) && list.images && (
                <img
                  css={styles.images}
                  alt="user"
                  src={publicPath(list.images)}
                />
              )}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

FilterOptionsList.propTypes = {
  filterOptions: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        optionVal: PropTypes.string,
        optionLabel: PropTypes.string,
        icon: PropTypes.string,
        images: PropTypes.string,
      })
    ),
  }),
  filterTitle: PropTypes.string,
  handleClick: PropTypes.func,
  resetFilters: PropTypes.bool,
  style: PropTypes.object,
};

export default FilterOptionsList;
