/** @jsxImportSource @emotion/react */
import { useState, useReducer, useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import useStyles from './styles';
import PropTypes from 'prop-types';
import getDateFormat from '../../utils/getDateFormat';

const CourseInfoConfirmPopup = props => {
  const styles = useStyles();

  return (
    <div css={styles.popupConfirm}>
      <div css={styles.heading}></div>
      <h2>Course summary</h2>
      <div css={styles.section1}>
        <div css={styles.line}>
          <img src="/images/common/location.svg" alt="Location"></img>
          <div>
            <h3 style={{ width: '136px' }}>{props.data?.courseLocation}</h3>
          </div>
        </div>

        <div css={styles.line}>
          <img src="/images/common/calender.svg" alt="Calender"></img>

          <div>
            <h3>{getDateFormat(props.data?.courseDate, 'dd-mm-yyyy')}</h3>
          </div>
        </div>

        <div css={styles.line}>
          <img src="/images/common/cap.svg" alt="Course Type"></img>

          <div>
            <h3>{props.data?.courseType}</h3>
          </div>
        </div>

        <div css={styles.line}>
          <img src="/images/common/mask.svg" alt="Coaches"></img>

          <div>
            <h3>{props.data?.noOfCoach} Coach developers</h3>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div css={styles.buttonWrapper}>
        <button css={styles.buttons} onClick={() => props?.confirmCourse()}>
          <p css={styles.titlebutton}>Confirm course</p>
        </button>
      </div>
    </div>
  );
};

CourseInfoConfirmPopup.propTypes = {
  data: PropTypes.shape({
    courseLocation: PropTypes.string,
    courseDate: PropTypes.string,
    courseType: PropTypes.string,
    noOfCoach: PropTypes.number,
  }).isRequired,
  confirmCourse: PropTypes.func.isRequired,
};

export default CourseInfoConfirmPopup;
