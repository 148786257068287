/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import 'react-calendar/dist/Calendar.css';
import useStyles from './styles';

const DeleteCoursePopup = props => {
  const styles = useStyles();
  const confirmDeleteCourse = () => {
    props.confirmDelete();
  };

  const cancelCourse = () => {
    console.log('Cancel');
    props.cancelDelete();
  };

  return (
    <div css={styles.popupDeleteConfirm}>
      <div css={styles.formField}>
        <label htmlFor css={styles.Coursename}>
          Delete course?
        </label>
        <br />
        <br />
        <p css={styles.message}>You can't undo this action</p>
        <br />
        <div css={styles.buttonWrapper}>
          <button css={styles.buttons} onClick={() => confirmDeleteCourse()}>
            <p css={styles.titlebutton}>Yes, delete course</p>
          </button>
          <br />
          <button
            css={styles.cancelDeleteCoursebtn}
            onClick={() => cancelCourse()}
          >
            <p css={styles.titlebutton}>Cancel</p>
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteCoursePopup.propTypes = {
  confirmDelete: PropTypes.func,
  cancelDelete: PropTypes.func,
};

export default DeleteCoursePopup;
