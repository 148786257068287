export const getVenueIcon = count => {
  const redPin = '/images/common/venue/pin-red.svg';
  const greenPin = '/images/common/venue/pin-green.svg';
  const yellowPin = '/images/common/venue/pin-yellow.svg';
  let venueIcon;
  count = count ? parseInt(count) : 0;

  if (count == 0) {
    venueIcon = redPin;
  } else if (count < 20) {
    venueIcon = yellowPin;
  } else {
    venueIcon = greenPin;
  }
  return venueIcon;
};

export const getVenueDefaultIcon = () => {
  return '/images/common/venue/pin-black.svg';
};

export const getVenueSelectedIcon = count => {
  const redPinSelected = '/images/common/venue/pin-red-selected.svg';
  const greenPinSelected = '/images/common/venue/pin-green-selected.svg';
  const yellowPinSelected = '/images/common/venue/pin-yellow-selected.svg';
  let venueIcon;
  count = count ? parseInt(count) : 0;

  if (count == 0) {
    venueIcon = redPinSelected;
  } else if (count < 20) {
    venueIcon = yellowPinSelected;
  } else {
    venueIcon = greenPinSelected;
  }
  return venueIcon;
};

export const getTrainerIcon = type => {
  const EFAIFIcon = '/images/common/trainer/efaif.svg';
  const FUTSALIcon = '/images/common/trainer/futsal.svg';
  const GKIcon = '/images/common/trainer/gk.svg';
  const I2CFIcon = '/images/common/trainer/12cf.svg';
  const UEFABIcon = '/images/common/trainer/uefa-b.svg';
  const UEFACIcon = '/images/common/trainer/uefa-c.svg';
  const WOCIcon = '/images/common/trainer/woc.svg';
  const userIcon = '/images/common/user-pin.svg';
  let trainerIcon;

  switch (type) {
    case 'EFAiF':
      trainerIcon = EFAIFIcon;
      break;
    case 'Futsal':
      trainerIcon = FUTSALIcon;
      break;
    case 'Goalkeeping':
      trainerIcon = GKIcon;
      break;
    case 'I2CF':
      trainerIcon = I2CFIcon;
      break;
    case 'UEFA B':
      trainerIcon = UEFABIcon;
      break;
    case 'UEFA C':
      trainerIcon = UEFACIcon;
      break;
    case 'WOC':
      trainerIcon = WOCIcon;
      break;
    default:
      trainerIcon = userIcon;
  }
  return trainerIcon;
};

export const getTrainerSelectedIcon = type => {
  const EFAIFSelectedIcon = '/images/common/trainer/efaif-selected.svg';
  const FUTSALSelectedIcon = '/images/common/trainer/futsal-selected.svg';
  const GKSelectedIcon = '/images/common/trainer/gk-selected.svg';
  const I2CFSelectedIcon = '/images/common/trainer/12cf-selected.svg';
  const UEFABSelectedIcon = '/images/common/trainer/uefa-b-selected.svg';
  const UEFACSelectedIcon = '/images/common/trainer/uefa-c-selected.svg';
  const WOCSelectedIcon = '/images/common/trainer/woc-selected.svg';
  const userIcon = '/images/common/user-pin.svg';
  let trainerIcon;

  switch (type) {
    case 'EFAiF':
      trainerIcon = EFAIFSelectedIcon;
      break;
    case 'Futsal':
      trainerIcon = FUTSALSelectedIcon;
      break;
    case 'Goalkeeping':
      trainerIcon = GKSelectedIcon;
      break;
    case 'I2CF':
      trainerIcon = I2CFSelectedIcon;
      break;
    case 'UEFA B':
      trainerIcon = UEFABSelectedIcon;
      break;
    case 'UEFA C':
      trainerIcon = UEFACSelectedIcon;
      break;
    case 'WOC':
      trainerIcon = WOCSelectedIcon;
      break;
    default:
      trainerIcon = userIcon;
  }
  return trainerIcon;
};

export const getCourseIcon = isConfirmed => {
  if (isConfirmed === 1) return '/images/common/venue/pin-green.svg';
  return '/images/common/venue/pin-yellow.svg';
};

export default () => ({
  getVenueIcon,
  getVenueSelectedIcon,
  getTrainerIcon,
  getTrainerSelectedIcon,
  getCourseIcon,
});
