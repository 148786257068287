import { BrowserRouter as Router } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';
import GlobalStyles from './components/GlobalStyles';
import ContextProviders from './context/contextProviders';
import initGTagManager from './gtm';
import AppRoutes from './routes';

// GTM dataLayer is advised to keep at the top level
initGTagManager();

// if it's a iframe url then add class in body
window.location.href.indexOf("external") > -1 ? 
document.body.classList.add('iframePage') : 
document.body.classList.remove('iframePage');

const App = () => (
  <div id="main">
    <GlobalStyles />
    <Router>
      <ErrorBoundary>
        <ContextProviders>
          <AppRoutes />
        </ContextProviders>
      </ErrorBoundary>
    </Router>
  </div>
);

export default App;
