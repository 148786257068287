import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, spacing, breakpoints, screenSizes, utility } = useTheme();

  return {
    venueActionCta: css`
      display: flex;
      //margin-left: 50px;
      //margin-top: 32px;
      gap: 32px;
      button {
        max-width: 150px;
        cursor: pointer;
      }

      // display: flex;
      // flex-direction: column;
      // color: ${colors.white};
      // padding: 53px 24px 53px 48px;
      // background: ${colors.black};
      // transform: translateX(-394px);
      // transition: transform 0.3s ease-in-out;

      position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    background: #1D1D1B;
    padding: 32px;
}
    `,

    overlay: css`
      width: 100%;
      height: 100%;
      background-color: rgba(29, 29, 27, 0.9);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    `,

    popupDeleteConfirm: css`
      position: fixed;
      top: 50%;
      left: 60%;
      transform: translate(-50%, -50%);
      display: flex;
      display: flex;
      //width: 511px;
      width: 25%;
      padding: 2%;
      flex-direction: column;
      align-items: flex-start;
      // gap: var(--Spacing-Spacing---lg, 24px);
      //gap: 2%;
      border-radius: var(--Spacing-Spacing---lg, 24px);
      border: 2px solid var(--Border-Border---primary, #e6c6ff);
      background: var(--Surface-Surface---white, #fff);
      max-height: 90%;
    `,

    formField: css`
      height: 100%;
      width: 100%;
      overflow: auto;
      padding: 0 2%;
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cd8eff;
        cursor: pointer;
      }
    `,

    divider: css`
      height: 0.5em;
      width: 100%;
      background: transparent;
    `,

    courseinfo: css`
      color: var(--Text-Text---invert, #1d1d1b);
      font-family: FS Dillon;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.244px;
    `,

    Coursename: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 130% */
      letter-spacing: -0.18px;
    `,

    text: css`
      display: flex;
      height: 80px;
      //padding:var(--Spacing-2, 16px);
      padding: 4%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      //gap: 10px;
      align-self: stretch;
      border-radius: var(--Radius-SM, 4px);
      border: 1px solid var(--Border-Border---white-bg, #b4b4b3);
      position: relative;

      .placeholder {
        &:focus-visible {
          outline: none;
          border: none;
        }
        border: none;
        resize: none;
        //padding-top:10px;

        color: var(--Text-Text---secondary, #4f4f4e);
        font-family: FS Dillon;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
        width: 100%;
        height: 100%;

        overflow: auto;
        ::-webkit-scrollbar {
          width: 4px;
        }
        ::-webkit-scrollbar-thumb {
          background: #cd8eff;
          cursor: pointer;
        }
      }
    `,

    datepickrdeleteBtn: css`
      background: none;
    `,

    submitbutton: isDisabled => css`
      padding: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #8f00ff;
      font-family: FS Dillon;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      background: ${isDisabled
        ? 'var(--Surface-Surface---light-grey, #E6E6E6)'
        : '#8F00FF'};
      border: none;
      cursor: ${isDisabled ? 'default' : 'pointer'};
      width: 100%;
      color: #fff;
    `,

    heading: css`
      color: var(--Text-Text---invert, #1d1d1b);
      font-family: FS Dillon;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 23.4px;
      letter-spacing: -0.18px;
    `,

    section1: css`
      padding: var(--Spacing-Spacing-xl, 32px) var(--Spacing-Spacing---lg, 24px);
      align-self: stretch;
      border-radius: var(--Spacing-Spacing---xs, 4px);
      background: var(--Surface-Surface---light-grey, #e6e6e6);
      display: grid;
      grid-template-columns: 50% 50%;
      row-gap: 24px;
      column-gap: 24px;

      img {
        width: var(--Spacing-Spacing-xl, 32px);
        height: var(--Spacing-Spacing-xl, 32px);
      }

      h3 {
        color: #1d1d1b;
        font-family: FS Dillon;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 23.4px; /* 130% */
        letter-spacing: -0.18px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    `,

    box1: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-Spacing---lg, 24px);
      width: 176px;
    `,

    line: css`
      display: flex;
      padding: 0px 0px 0px 0px;
      align-items: center;
      gap: 8px;
    `,

    box2: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-Spacing---lg, 24px);
      width: 50% 145px;
    `,

    buttonWrapper: css`
      width: 100%;
    `,

    buttons: css`
      display: flex;
      height: 54px;
      width: 100%;
      padding: 22px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border: none;
      border-radius: 4px;
      background: var(--Surface-Surface---brand, #8f00ff);
      cursor: pointer;
    `,

    titlebutton: css`
      color: var(--Text-Text---primary, #fff);
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    `,
    deleteCourseBtn: css`
      display: flex;
      height: 54px;
      width: 100%;
      padding: 22px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border: none;
      border-radius: 4px;
      background: var(--Surface-Surface---brand, #8f00ff);
      cursor: pointer;
    `,
    cancelDeleteCoursebtn: css`
      width: 100%;
      height: 54px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      gap: 10px;
      border-radius: 4px;
      border: none;
      background: transparent;
      cursor: pointer;
      font-family: FS Dillon;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: #1d1d1b;
      border: 2px solid var(--Border-Border---primary, #e6c6ff);
      cursor: pointer;
      p {
        color: #1d1d1b;
      }
    `,
    message: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.16px;
    `,
  };
};
