/** @jsxImportSource @emotion/react */
import { useRef } from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
import useScrollTop from '../../hooks/useScrollTop';

const Page = ({ children }) => {
  const styles = useStyles();
  const pageRef = useRef();
  useScrollTop(pageRef);

  return (
    <div className="spa-scroll-top-fix" ref={pageRef} tabIndex={-1}>
      <div css={styles.base}>
        <div css={styles.pageWrapper}>
          <div css={styles.maincontent}>{children}</div>
        </div>
      </div>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
