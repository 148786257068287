/** @jsxImportSource @emotion/react */
import { Link as LinkRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Link = ({ children, to, ...rest }) => {
  const styles = useStyles();

  return (
    <LinkRouter to={to} css={styles.link} {...rest}>
      {children}
    </LinkRouter>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

export default Link;
