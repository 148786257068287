import { Route, Switch } from 'react-router-dom';
import DontKnowMyFan from '../components/Account/DontKnowMyFan';
import SignInFan from '../components/Account/SignInFan';
import SignUp from '../components/Account/SignUp';
// import CourseVenueEdit from './../components/CourseVenueEdit';
// import CourseVenueList from './../components/CourseVenueList';
// import CourseVenueMap from './../components/CourseVenueMap';
// import CourseTrainerMap from './../components/CourseTrainerMap';
// import CourseTrainerList from './../components/CourseTrainerList';
// import CourseManagementMap from './../components/CourseManagementMap';
// import CourseManagementList from './../components/CourseManagementList';
// import CourseManagementNew from './../components/CourseManagementNew';
// import CourseManagementAddNew from './../components/CourseManagementAddNew';
import CourseManagementLayout from './../components/CourseManagementLayout';
import { setLocalItem } from '../hooks/useLocalStorage';
import Page from './../components/Page';
import Page404 from './../components/Page404';

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Page>
          <CourseManagementLayout />
        </Page>
      </Route>
      <Route path="/courses/map">
        <Page>
          <CourseManagementLayout />
        </Page>
      </Route>
      <Route path="/courses/addnew">
        <Page>
          <CourseManagementLayout />
        </Page>
      </Route>
      {/* <Route
        exact
        path="/courses/update/:courseInstanceId/:venueId/:redirectPage"
      >
        <Page>
          <CourseManagementNew />
        </Page>
      </Route> */}
      {/* <Route exact path="/venues/map">
        <Page>
          <CourseVenueMap />
        </Page>
      </Route> */}
      {/* <Route exact path="/venues/list">
        <Page>
          <CourseVenueList />
        </Page>
      </Route> */}
      {/* <Route exact path="/venues/addnew">
        <Page>
          <CourseVenueEdit />
        </Page>
      </Route> */}
      {/* <Route exact path="/editvenue/:venueId">
        <Page>
          <CourseVenueEdit />
        </Page>
      </Route> */}
      {/* <Route exact path="/trainers/map">
        <Page>
          <CourseTrainerMap />
        </Page>
      </Route> */}
      {/* <Route exact path="/trainers/list">
        <Page>
          <CourseTrainerList />
        </Page>
      </Route> */}
      {/* <Route exact path="/homepage/:matchid">
        <Page>
          <HomePage />
        </Page>
      </Route> */}

      <Route exact path="/account/signup">
        <SignUp />
      </Route>
      <Route exact path="/account/signinfan">
        <SignInFan />
      </Route>
      <Route exact path="/account/idontknowmyfan">
        <DontKnowMyFan />
      </Route>
      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
