import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, borderRadius } = useTheme();

  return {
    dropDownButton: status => css`
    width: 135px;
    height: 32px;
    margin: 0;
    padding: 4px 8px 4px 14px;
    background-color: transparent;
    border-radius: var(--Spacing-7, 56px);
    border: 1px solid; 
    border-color: ${status === 1 ? '#B4B4B3' :
    status === 2 ? '#FFB800'
    : status === 3 ? '#20BE56'
    : '#DF1C41'};
    color: #FFF;
    font-family: FS Dillon;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-align:left;
    position: relative;

    ::before{
      content: ${status === 1 ? 'url(/images/common/list/gray-dot.svg)' :
      status === 2 ? 'url(/images/common/list/orange-triangle.svg)'
      : status === 3 ? 'url(/images/common/list/green-dot.svg)'
      : 'url(/images/common/list/red-square.svg)'};
      position: relative;
      margin-right: 12px;
    }
    `,
    
    arrow: isOptionsOpen => css`
      position: absolute;
      right: 4px;
      top: 0;
      bottom: 0;
      padding: 5px 10px;
      text-align: center;
      border-radius: 0 ${borderRadius.s} ${borderRadius.s} 0;
      background-color: transparent;
      display: grid;
      color: ${colors.white};
      align-items: center;
      svg {
        transform: rotate(${isOptionsOpen ? '180deg' : '0deg'});
        > g {
          fill: transparent;
        }
      }
    `,
    container: css`
      position: relative;
      ul {
        border: 1px solid ${colors.lightGrey};
        list-style: none;
        padding: ${borderRadius.m} 0;
        position: absolute;
        width: 100%;
        z-index: 2;
        max-height: 300px;
        overflow-y: auto;

        ::-webkit-scrollbar {
          width: 4px;
        }
        ::-webkit-scrollbar-thumb {
          background: #8F00FF; 
        }      
        ::-webkit-scrollbar-track {
          background-color: ${colors.white};
          -webkit-border-radius: 1rem;
          border-radius: 1rem;
        }

        li {
          padding: 8px 16px;
          color: ${colors.black};
          cursor: pointer;
          font-size: 16px;
          line-height: 24px;
          height: 56px;
          display: flex;
          align-items: center;

          &:active,
          &:focus,
          &:hover,
          &[aria-selected='true'] {
            background: #e6e6e6;
          }
        }
      }
    `,
    list: css`
      background-color: ${colors.white};
    `,
    selectWrapper: css`
      position: relative;
      select {
        color: ${colors.darkGrey};
        width: 100%;

        // supports in IOS versions only
        @supports (-webkit-touch-callout: none) {
          height: 33px;
          color: ${colors.darkGrey};
          background-color: ${colors.white};
        }
      }
    `,
    holder: css`
      position: absolute;
      display: inline-block;
      right: 0;
      top: 0;
      width: 50px;
      height: 32px;
      background: url(${publicPath('/images/common/dropdown.jpg')}) no-repeat
        right center;
      pointer-events: none;
    `,
  };
};
