import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { breakpoints, colors, screenSizes, spacing } = useTheme();

  return {
    base: css`
      background: ${colors.black};
    `,
    pageWrapper: css`
      display: grid;
      //grid-template-columns: 283px auto;
    `,
    maincontent: css`
      background: ${colors.white};
      color: ${colors.darkBlue};
      //border-left: 1px solid #4F4F4E;
      position:relative;
      //margin: auto;
    `,
  };
};
