/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { SpinningBadge } from '../Icons';
import Text from '../Text';
import useStyles from './styles';

const Loader = forwardRef(({ message, inline, ...rest }, ref) => {
  const styles = useStyles();

  const labelId = 'spinning-loader-text';

  return (
    <div css={styles.loader(inline, 'spinning-loader')} {...rest}>
      <SpinningBadge aria-labelledby={labelId} animationId="spinning-loader" />
      <Text
        role="text"
        size={6}
        id={labelId}
        css={styles.text(!!message, inline)}
        tabIndex={-1}
        ref={ref}
      >
        {message || 'Loading'}
      </Text>
    </div>
  );
});

Loader.propTypes = {
  message: PropTypes.string,
  inline: PropTypes.bool,
};

export default Loader;
