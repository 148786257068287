import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const OnboardingDataContext = createContext({
  setOnboardingContext: () => { },
});

export const OnboardingDataProvider = ({ children }) => {
  const [OnboardingContext, setOnboardingContext] = useState({});

  const setOnboardingDataContext = useCallback(value => {
    if (!value) {
      return;
    }
    setOnboardingContext(value);
  }, []);

  return (
    <OnboardingDataContext.Provider
      value={{ OnboardingContext, setOnboardingDataContext }}
    >
      {children}
    </OnboardingDataContext.Provider>
  );
};

OnboardingDataProvider.propTypes = {
  children: PropTypes.node,
};

export const useOnboardingDataContext = () =>
  useContext(OnboardingDataContext);
