import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const SetHeaderHeightContext = createContext();

export const SetHeaderHeightProvider = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState();

  // set header height click handler
  const setHeaderHeightValue = useCallback(value => {
    setHeaderHeight(value);
  }, []);

  return (
    <SetHeaderHeightContext.Provider
      value={{ headerHeight, setHeaderHeightValue }}
    >
      {children}
    </SetHeaderHeightContext.Provider>
  );
};

SetHeaderHeightProvider.propTypes = {
  children: PropTypes.node,
};

export const useSetHeaderHeightContext = () =>
  useContext(SetHeaderHeightContext);
