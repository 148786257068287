import axios from 'axios';

import {
  Api404,
  Api500,
  CLUB,
  KICKABOUT_CASUAL,
  MAX_DURATION,
  MAX_DURATION_OVERRIDE,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
  SESSION_DURATION,
  UnspecifiedError,
} from '../const';
import AppError from '../utils/AppError';

const apiUrlMap = {
  [KICKABOUT_CASUAL]: 'casualrecommendation',
  [CLUB]: 'clubrecommendation',
};

const miniOnboardingApiUrlMap = {
  [KICKABOUT_CASUAL]: 'sessionrecommendation',
  [CLUB]: 'teamsrecommendation',
};

const apiMLUrlMap = {
  [KICKABOUT_CASUAL]: 'mlcasualrecommendation',
  [CLUB]: 'mlclubrecommendation',
};

const errorMapping = {
  404: Api404,
  500: Api500,
};

const baseURL = process.env.REACT_APP_API_URL;
const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
const baseExternalURL = process.env.REACT_APP_API_URL_EXTERNAL;
const subscriptionKeyAccreditations =
  process.env.REACT_APP_SUBSCRIPTION_KEY_ACC;
const siteCoreBaseURL = process.env.REACT_APP_SITECORE_API_URL;
const domain = 'https://courseplanningapi.azurewebsites.net/api';
//const domain = 'https://facourse.azurewebsites.net/api';

const baseHeaders = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': subscriptionKey,
};

const service = axios.create({
  baseURL,
  headers: baseHeaders,
});

service.interceptors.response.use(undefined, error => {
  const status = error.response?.status.toString();

  throw new AppError(
    status && status in errorMapping ? errorMapping[status] : UnspecifiedError
  );
});

export const getCourseVenueList = () => {
  return service.get(`${domain}/venue`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getCourseVenueData = venueId => {
  return service.get(
    `${domain}/venue/${venueId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const updateCourseVenueData = (venueId, venueData) => {
  return service.put(
    `${domain}/venue/${venueId}`,
    venueData,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getCourseTrainerList = () => {
  return service.get(`${domain}/Trainer`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getCourseList = () => {
  return service.get(`${domain}/Course`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getCourseInstanceDetails = () => {
  return service.get(
    `${domain}/CourseInstance/Details`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getCourseGroups = () => {
  return service.get(
    `${domain}/CourseGroup`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export const getCourseGroupById = courseGroupId => {
  return service.get(
    `${domain}/CourseGroup/${courseGroupId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getCourseGroupsWithTrainers = () => {
  return service.get(
    `${domain}/CourseGroup/GetWithTrainers`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}


export const getCourseInstanceByGroup = courseGroupId => {
  return service.get(
    `${domain}/CourseInstance/GetByGroup/${courseGroupId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export const getCourseGroupDetail = courseGroupId => {
  return service.get(
    `${domain}/CourseGroup/Detail/${courseGroupId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export const createCourseGroup = courseData => {
  return service.post(
    `${domain}/CourseGroup`,
    courseData
  );
};



export const updateCourseGroup = (courseData,courseGroupId) => {
  return service.put(
    `${domain}/CourseGroup/${courseGroupId}`,
    courseData
  );
}

export const deleteCourseGroup = courseData => {
  return service.delete(
    `${domain}/CourseGroup`,
    { data: courseData }
  );
};

export const deleteCourseInstance = courseInstanceData => {
  return service.delete(
    `${domain}/CourseInstance`,
    { data: courseInstanceData }
  );
};

export const getTrainersCourseGroup = courseGroupID => {
  return service.get(
    `${domain}/CourseGroupTrainer/GetTrainersByCourseGroup/${courseGroupID}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const createTrainersCourseGroup = trainerData => {
  return service.post(
    `${domain}/CourseGroupTrainer`,
    trainerData
  );
};

export const updateTrainersCourseGroup = trainerData => {
  return service.put(
    `${domain}/CourseGroupTrainer`,
    trainerData
  );
};

export const updateCourseInstance = (courseInstanceData,courseInstanceId) => {
  return service.put(
    `${domain}/CourseInstance/${courseInstanceId}`,
    courseInstanceData
  );
}


export const courseInstanceApi = courseData => {
  return service.post(
    `${domain}/CourseInstance`,
    courseData
  );
};

export const trainersCourseInstanceApi = trainerData => {
  return service.post(
    `${domain}/TrainersCourseInstance`,
    trainerData
  );
};

export const updateTrainersCourseInstance = trainerData => {
  return service.put(
    `${domain}/TrainersCourseInstance`,
    trainerData
  );
};

export const updateVenuesCourseInstance = venueData => {
  return service.put(
    `${domain}/VenuesCourseInstance`,
    venueData
  );
};

export const createVenueCourseInstance = venueData => {
  return service.post(
    `${domain}/VenuesCourseInstance`,
    venueData
  );
};

export const getCourseInstanceApi = courseInstanceID => {
  return service.get(
    `${domain}/CourseInstance/${courseInstanceID}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getTrainersCourseInstanceApi = courseInstanceID => {
  return service.get(
    `${domain}/TrainersCourseInstance/${courseInstanceID}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getVenueCourseInstanceApi = courseInstanceID => {
  return service.get(
    `${domain}/VenuesCourseInstance/${courseInstanceID}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getVenuesByDistance = (latitude, longitude) => {
  return service.get(
    `${domain}/Venue/GetByDistance?lat=${latitude}&lon=${longitude}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getTrainersByDistance = (latitude, longitude) => {
  return service.get(
    `${domain}/Trainer/GetByDistance?lat=${latitude}&lon=${longitude}`,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );
};

export const updateCourseInstanceApi = (courseData, courseInstanceID) => {
  return service.put(
    `${domain}/CourseInstance/${courseInstanceID}`,
    courseData
  );
};

export const deleteTrainersCourseInstanceApi = courseData => {
  return service.delete(
    `${domain}/TrainersCourseInstance`,
    { data: courseData }
  );
};
