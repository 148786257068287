import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const SetFixtureDataContext = createContext();

export const SetFixtureDataProvider = ({ children }) => {
  const [fixtureData, setFixtureData] = useState();

  // set header height click handler
  const setFixtureDataValue = useCallback(value => {
    // console.log(value);
    setFixtureData(value);
  }, []);

  return (
    <SetFixtureDataContext.Provider
      value={{ fixtureData, setFixtureDataValue }}
    >
      {children}
    </SetFixtureDataContext.Provider>
  );
};

SetFixtureDataProvider.propTypes = {
  children: PropTypes.node,
};

export const useSetFixtureDataContext = () =>
  useContext(SetFixtureDataContext);
