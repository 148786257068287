import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
    const { colors, spacing, breakpoints, screenSizes, utility } = useTheme();

    return {
        headerWrapper: css`
    width: 100%;
    //height: 133px;
    //padding: 40px 10% 20px 21%;
    padding: 40px 10% 20px 24%;
    //padding-top: 32px;
    //padding-bottom: 45px;
    display: flex;
    position:absolute;
    //z-index:2;
    z-index:1;
    //background-image: linear-gradient(to top, rgba(29,29,27,0), rgba(29,29,27,1));
  `,
        headerBtnSectionLeft: css`
    display: flex;
    width: 50%;
    justify-content: start;
  `,
        headerBtnSectionRight: css`
    display: flex;
    //width: 50%;
    justify-content: end;
    gap: 8px;
    position: fixed;
    right: 4%;
    `,
        viewBtn: css`
    display: flex;
    min-width: 120px;
    height: 56px;
    padding: var(--Spacing-Spacing---md, 16px) var(--Spacing-Spacing---lg, 24px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: var(--Spacing-Spacing---sm, 8px);
    border: 2px solid var(--Border-Border---input, #E6E6E6);
    background: rgba(29, 29, 27, 0.70);
    cursor:pointer;

    //background: url(${publicPath('/images/common/leftLinks/edit.svg')})
                    no-repeat;

    color: var(--Text-Text---primary, #FFF);
    font-family: FS Dillon;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.14px;
    text-decoration:none;
    
    &.active{
    background: var(--Surface-Surface---tertiery, #B7C5E9);
    border: none;
    color: var(--Text-Text---invert, #1D1D1B);
    }
    `,
        viewBtnActive: css`
    background: var(--Surface-Surface---tertiery, #B7C5E9);
    border: none;
    color: var(--Text-Text---invert, #1D1D1B);
    `,
        addCourseBtn: css`
    display: inline-flex;
    padding: var(--Spacing-Spacing---md, 16px) var(--Spacing-Spacing---lg, 24px) var(--Spacing-Spacing---md, 16px) var(--Spacing-Spacing-xl, 32px);
    align-items: center;
    position: fixed;
    right: 4%;
    bottom: 33px;
    border-radius: var(--Spacing-Spacing---sm, 8px);
    background: var(--TT---PURPLE, #8F00FF);
    box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.75), 0px 1px 10px 0px rgba(95, 8, 164, 0.47);
    border-style: none;
    color: var(--UI-PL-UI-White, #FFF);
    leading-trim: both;
    text-edge: cap;
    font-family: FS Dillon;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
    cursor: pointer;
    text-decoration:none;
    `,
    
    logo: css`
    width: max-content;
    height: auto;
    `,

        pdfDownload: utility.checkboxHidden,
    };
};
