import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors } = useTheme();

  return {

    popup2: css`
      position: fixed;
      top: 50%;
      left: 60%;
      transform: translate(-50%, -50%);
      display: flex;
      display: flex;
      //width: 511px;
      width: 30%;
      padding: 2%;
      flex-direction: column;
      align-items: flex-start;
      // gap: var(--Spacing-Spacing---lg, 24px);
      //gap: 2%;
      border-radius: var(--Spacing-Spacing---lg, 24px);
      border: 2px solid var(--Border-Border---primary, #e6c6ff);
      background: var(--Surface-Surface---white, #fff);
      max-height: 90%;
    `,

    formField: css`
      height: 100%;
      overflow: auto;
      padding: 0 2%;
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cd8eff;
        cursor: pointer;
      }
    `,

    divider: css`
      height: 0.5em;
      width: 100%;
      background: transparent;
    `,

    courseinfo: css`
      color: var(--Text-Text---invert, #1d1d1b);
      font-family: FS Dillon;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.244px;
    `,

    Coursename: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 130% */
      letter-spacing: -0.18px;
    `,

    text: css`
      display: flex;
      height: 80px;
      //padding:var(--Spacing-2, 16px);
      padding: 4%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      //gap: 10px;
      align-self: stretch;
      border-radius: var(--Radius-SM, 4px);
      border: 1px solid var(--Border-Border---white-bg, #b4b4b3);
      position: relative;

      .placeholder {
        &:focus-visible {
          outline: none;
          border: none;
        }
        border: none;
        resize: none;
        //padding-top:10px;

        color: var(--Text-Text---secondary, #4f4f4e);
        font-family: FS Dillon;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
        width: 100%;
        height: 100%;

        overflow: auto;
        ::-webkit-scrollbar {
          width: 4px;
        }
        ::-webkit-scrollbar-thumb {
          background: #cd8eff;
          cursor: pointer;
        }
      }
    `,

    pencil: css`
      //margin-left:366px;
      //margin-top:-62px;
      position: absolute;
      top: 8px;
      right: 8px;
    `,

    datebox: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `,

    date: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 130% */
      letter-spacing: -0.18px;
    `,

    content: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      //line-height: 24px;
      letter-spacing: -0.16px;
    `,
    addNewDate: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      //line-height: 24px;
      letter-spacing: -0.16px;
      text-decoration: underline;
      background:none;
      border: none;
      cursor: pointer;
    `,
    datePickerDiv: css`
      display: flex;
      width: 100%;
      border-radius: var(--Radius-SM, 4px);
      border: 1px solid var(--Border-Border---white-bg, #b4b4b3);
      background-color: ${colors.white};
      padding: 1px;
      margin-bottom: 8px;

      .rmdp-week-day,
      .rmdp-day {
        color: ${colors.lightGrey};
      }
      .rmdp-container {
        width: 100% !important;
        display: block !important;
      }
      .rmdp-arrow-container:hover {
        background-color: var(--TT---PURPLE, #8f00ff);
        .rmdp-arrow {
          border-color: ${colors.white};
        }
      }
      .rmdp-header span .rmdp-arrow {
        border: solid var(--TT---PURPLE, #8f00ff);
        border-width: 0 2px 2px 0;
      }
      .rmdp-day.rmdp-selected span:not(.highlight) {
        background-color: var(--TT---PURPLE, #8f00ff);
        box-shadow: 0 0 3px #8798ad;
      }
      .rmdp-day.rmdp-today span {
        background-color: var(--TT---PURPLE, #8f00ff);
      }
      .rmdp-disabled{
        opacity:0.7;
      }
    `,

    datepickerBtn: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 0px var(--Spacing-2, 16px);
      //width:100%;
      width: 100%;
      border: none;
      background-color: ${colors.white};
      height: var(--Spacing-7, 48px);

      label {
        cursor: pointer;
        font-family: FS Dillon;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #4f4f4e;
      }
    `,
    dropDownButton: css`
      color: #1d1d1b;
      background: transparent;
      padding: 10px 16px;
      border-radius: 4px;
      border: 1px solid var(--Border-Border---white-bg, #b4b4b3);
      width: 100%;
      max-width: 100% !important;
      justify-content: flex-start;
      text-align: left;
      line-height: 24px;
      height: 50px;
      font-family: FS Dillon;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: -0.01em;

      &:hover,
      &:not(:disabled):hover {
        cursor: pointer;
      }
    `,


    submitbutton: isDisabled => css`
      padding: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #8f00ff;
      font-family: FS Dillon;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      background: ${isDisabled
        ? 'var(--Surface-Surface---light-grey, #E6E6E6)'
        : '#8F00FF'};
      border: none;
      cursor: ${isDisabled ? 'default' : 'pointer'};
      width: 100%;
      color: #fff;
    `,


    error: css`
      color: var(--Text-Text---primary, #f00);
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      //line-height: 24px;
      letter-spacing: -0.16px;
    `,

    venueSwitch:css`
    display: flex;
    align-items: center;
    gap: 8px;
    `,
  };
};