/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import useStyles from './styles';

const Switch = ({ isChecked, setChecked }) => {
  const styles = useStyles();
  return (
    <label htmlFor="toggleSwitch" css={styles.switch}>
      {/* <div></div> */}
      <input
        id="toggleSwitch"
        type="checkbox"
        checked={isChecked}
        onChange={() => setChecked(!isChecked)}
      />
      <span className="slider" />
    </label>
  );
};

Switch.propTypes = {
  isChecked: PropTypes.bool,
  setChecked: PropTypes.func,
};

export default Switch;
