/** @jsxImportSource @emotion/react */
import useStyles from './styles';
import PropTypes from 'prop-types';

const externalLinkAttributes = {
  target: '_blank',
  rel: 'nofollow noopener noreferrer',
};

const ExternalLink = ({ children, href, ...rest }) => {
  const styles = useStyles();
  const isAbsoluteUrl = /^https?:\/\//i.test(href);

  const attributes = {
    ...(isAbsoluteUrl ? externalLinkAttributes : {}),
    ...rest,
  };

  return (
    <a href={href} css={styles.link} {...attributes}>
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

export default ExternalLink;
