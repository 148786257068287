/** @jsxImportSource @emotion/react */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Text = forwardRef(({ as: Tag = 'p', size, children, ...rest }, ref) => {
  const styles = useStyles();

  return (
    <Tag css={styles.text(size)} {...rest} ref={ref}>
      {children}
    </Tag>
  );
});

Text.propTypes = {
  as: PropTypes.elementType, 
  size: PropTypes.string, 
  children: PropTypes.node, 
};

export default Text;
