import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { breakpoints, borderRadius, colors, typography, spacing } = useTheme();

  return {
    base: css`
      display: flex;
      flex-direction: column;
      color: ${colors.white};
      background: ${colors.black};
    `,
    filterOptionsTitle: css`
      font-size: 15.63px;
      
      line-height: 15.63px;
      letter-spacing: -0.156px;
      padding-bottom: 16px;
    `,
    filterOptions: css`
      display: flex;
      flex-direction: inherit;
      gap: 8px;  
      width:100%;
    `,
    option: css`
      position: relative;
      flex:1;
      label {
        border: 1px solid #E6E6E6;
        border-radius: 4px;
        //padding: 16px 24px 16px 16px;
        //padding: 16px;
        padding: 12px 24px 12px 16px;
        background: ${colors.white};
        display: flex;
       // width: 100%;
        color: ${colors.black};
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        cursor: pointer;
        align-items: center;
        position:relative;
        height:auto;

        
        
        img {
          margin-right: 12px;
        }



      
      }
      input {
        width: 1px;
        height: 1px;
        border: none;
        position: absolute;
        &:checked {
          & + label {
            border: 1px solid #B7C5E9;
            // background: #B7C5E9 url(${publicPath('/images/common/filter/check_circle.svg')}) no-repeat
            // calc(100% - 12px) center;
            background: #B7C5E9 url(${publicPath('/images/common/filter/check_circle.svg')}) no-repeat
            calc(100% - 4px) center;
          }
        }
      }     
    `,

  
images:css`
position:absolute;
right:8px;

`,


  };
};
