/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ArrowTriangle } from '../Icons';
import useStyles from './styles';

const CustomStatusDropDown = ({
  id,
  optionsList,
  selectedDefaultValue,
  ...props
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOptionId, setSelectedOptionId] =
    useState(selectedDefaultValue);
  const styles = useStyles();

  let menuRef = useRef();

  useEffect(() => {
    let handler = e => {
      if (!menuRef.current.contains(e.target)) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleOptionSelect = optionId => {
    setSelectedOptionId(optionId);
    setIsOptionsOpen(false);

    props?.onChange(
      optionId,
      props?.type,
      props?.refObject,
      props?.refId,
      props?.action,
      props?.locationObj
    );
  };

  const selectedOption = optionsList.find(
    option => option.id === selectedOptionId
  );

  return (
    <div css={styles.wrapper} id={id} ref={menuRef}>
      <div css={styles.container}>
        <button
          type="button"
          onClick={toggleOptions}
          css={
            props.style ? props.style : styles.dropDownButton(selectedOptionId)
          }
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          tabIndex={0}
          //onKeyDown={toggleOptions}
          className={props.class}
        >
          {optionsList.length > 0 && selectedOption?.option}
          <span css={styles.arrow(isOptionsOpen)}>
            <ArrowTriangle width={10} height={5} />
          </span>
        </button>
        {isOptionsOpen && optionsList.length > 0 && (
          <ul
            //role="listbox"
            //tabIndex={-1}
            css={styles.list}
          >
            {optionsList.map((data, index) => {
              return (
                <li key={data.id}>
                  <button
                    onClick={() => handleOptionSelect(data.id)}
                    onKeyDown={() => handleOptionSelect(data.id)}
                    style={{ all: 'unset', width: '100%' }}
                  >
                    {data.option}
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

CustomStatusDropDown.propTypes = {
  id: PropTypes.string,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      option: PropTypes.string,
    })
  ),
  selectedDefaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  type: PropTypes.string,
  refObject: PropTypes.object,
  refId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  action: PropTypes.string,
  locationObj: PropTypes.object,
  style: PropTypes.object,
  class: PropTypes.string,
};

export default CustomStatusDropDown;
