import { useMsal } from '@azure/msal-react';
import { createContext, Fragment, useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import pageMetaInfo from '../pageMetaInfo';

export const PageInfoContext = createContext();

export const PageInfoProvider = ({ children }) => {
  const [pageInfo, setPageInfo] = useState();
  const { accounts } = useMsal();
  const { title, description } = pageInfo || {};
  const isLoggedIn = accounts.length > 0;

  const setPageInfoFromDefault = (pageName, pageTitleTokens) => {
    const info = pageMetaInfo[pageName];

    if (!info) {
      return;
    }

    const pageInfo = {
      ...info,
      ...(typeof info.title === 'function' && {
        title: info.title(pageTitleTokens),
      }),
    };

    setPageInfo(pageInfo);

    if (pageInfo && pageInfo.title) {

      // remove duplicate pages from dataLayer
      window.dataLayer.filter((data, index) => {
        return data.hasOwnProperty('page_name') ? window.dataLayer.splice(index, 1) : data
      })

      // GTM dataLayer
      window.dataLayer.push({
        page_name: pageInfo?.title,
        page_type: pageInfo?.pageType,
        page_url: window.location.href,
        loginId: isLoggedIn ? accounts[0]?.idTokenClaims.extension_FANCode : "Anonymous",
        loginStatus: isLoggedIn ? "yes" : "no",
        logintype: "FAN",
        userStatus: (isLoggedIn && accounts[0]?.idTokenClaims?.hasOwnProperty('newUser')) ? "New User" :
          isLoggedIn ? "Existing User" : null,
      });
    }
  };

  const navigationAssertive = useMemo(
    () => (title ? `Navigated to ${title}` : ''),
    [title]
  );

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {/* This region is used to announce route changes */}
      <div className="sr-only" aria-live="assertive">
        {navigationAssertive}
      </div>
      <PageInfoContext.Provider value={[pageInfo, setPageInfoFromDefault]}>
        {children}
      </PageInfoContext.Provider>
    </Fragment>
  );
};

PageInfoProvider.propTypes = {
  children: PropTypes.node,
};

export const usePageInfo = () => useContext(PageInfoContext);
