import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { utility, colors, spacing, borderRadius } = useTheme();

  return {
    switch: css`
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      border-radius: ${borderRadius.m};

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${colors.disabled};
        border-radius: ${borderRadius.m};
        transition:0.4s;

        &:before {
          position: absolute;
          content: '';
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: ${colors.white};
          transition:0.4s;
          border-radius: ${borderRadius.m};
        }
      }

      input:checked + .slider:before{
        transform: translateX(26px);
      }

      input:checked + .slider {
        background-color: ${colors.activeGreen}
      }
    `,
  };
};
